<template>
    <section class="content">
        <div class="container-fluid">
          <div class="row">
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        Mata Pelajaran
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h5 class="text-bold">{{guru.mapel}} </h5>                            
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">                            
                            <router-link to='/tanya-guru' class="btn btn-sm btn-secondary">
                            <i class="fas fa-arrow-left"></i> Ke Daftar Mapel
                    		    </router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
        <div class="col-md-12">
            <div class="card card-danger direct-chat direct-chat-primary">
            <div class="card-header">
                <h3 class="card-title">Direct Chat</h3>
                <div class="card-tools">
                <span data-toggle="tooltip" title="3 New Messages" class="badge badge-light" v-if="false">3</span>
                <button type="button" class="btn btn-tool" data-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
                </div>
            </div>
    <!-- /.card-header -->
            <div class="card-body">
                <!-- Conversations are loaded here -->
            <div class="direct-chat-messages">
            <!-- Message. Default to the left -->
                <div v-for="row in optChat" v-bind:key="row.id"
                v-bind:value="row.id">
                <div class="direct-chat-msg" v-if="row.type == 'bot'">
                    <div class="direct-chat-infos clearfix">
                    <span class="direct-chat-name float-left">Taruna Admin</span>
                    <span class="direct-chat-timestamp float-right">{{row.created_by}}</span>
                    </div>
                    <!-- /.direct-chat-infos -->
                    <img class="direct-chat-img" width="10px" src="https://img.icons8.com/external-becris-lineal-becris/64/000000/external-user-mintab-for-ios-becris-lineal-becris.png"/>
                    <!-- <img :src="url + '/uploads/default/unknown_user.jpg'" alt="message user image"> -->
                    <!-- /.direct-chat-img -->
                    <div class="direct-chat-text">{{row.content}}</div>
                    <!-- /.direct-chat-text -->
                </div>

                <div class="direct-chat-msg right mb-1" v-if="row.type == 'user'">
                    <div class="direct-chat-infos clearfix">
                    <span class="direct-chat-name float-right">{{row.siswa}}</span>
                    <span class="direct-chat-timestamp float-left">{{row.created_by}}</span>
                    </div>
                    <!-- /.direct-chat-infos -->
                    <img class="direct-chat-img" width="10px" src="https://img.icons8.com/external-becris-lineal-becris/64/000000/external-user-mintab-for-ios-becris-lineal-becris.png"/>
                    <!-- /.direct-chat-img -->
                    <div class="direct-chat-text" v-if="row.type_chat == 'text'">{{row.content}}</div>
                    <div class="direct-chat-text" v-if="row.type_chat == 'img'"><img :src="url+'/file/image/'+row.img_code" :alt="row.img_code" id="imgPreview" @click="popupImg(row.img_code)" width="50%;"></div>
                    <!-- /.direct-chat-text -->
                </div>
                <!-- /.direct-chat-msg -->
                </div>
            </div>
                <!--/.direct-chat-messages-->
                <!-- Contacts are loaded here -->
                <div class="direct-chat-contacts">
                <ul class="contacts-list" v-if="false">
                    <li>
                    <a href="#">
                        <img class="contacts-list-img" src="/docs/3.0/assets/img/user1-128x128.jpg">
                        <div class="contacts-list-info">
                        <span class="contacts-list-name">
                            Count Dracula
                            <small class="contacts-list-date float-right">2/28/2015</small>
                        </span>
                        <span class="contacts-list-msg">How have you been? I was...</span>
                        </div>
                        <!-- /.contacts-list-info -->
                    </a>
                    </li>
                    <!-- End Contact Item -->
                </ul>
                <!-- /.contacts-list -->
                </div>
                <!-- /.direct-chat-pane -->
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
                <form role="form" @submit="submitForm">
                <div class="input-group">
                    <input type="hidden" name="thread_id" v-model="form.thread_id" class="form-control">
                    <input type="text" @input="onChange($event)" name="content" v-model="form.content" placeholder="Ketik pesan ..." class="form-control" autocomplete="off">
                    <span class="input-group-append">
                    <button type="submit" class="btn btn-primary" :disabled="!form.content">Kirim</button>
                    <button class="btn btn-secondary" type="button" @click="btnFile"><i class="fas fa-upload"></i></button>
                    </span>
                    <input type="file" id="uploadFile" name="uploadFile"
                    accept="image/png, image/jpeg" @change="uploadFoto(this)" hidden>
                </div>
                </form>
            </div>
            <!-- /.card-footer-->
            </div>
        </div>
            </div>
        </div>
    <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static" >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <form role="form">
                <div class="modal-header">
                <h5 class="modal-title">Preview Gambar</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <div class="row">
                <div v-for="row in imgPreview" v-bind:key="row.img_code">
                    <img :src="url+'/file/image/'+row.img_code" :alt="row.img_code" width="100%;">
                </div>
                </div>
                </div>
                <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Tutup
                </button>
                </div>
            </form>
            </div>
        </div>
    </div>
    </section>
</template>
<script>
import { auth, authFetch } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

    export default {
    name: 'Tanya',
    data: () => ({
        guru: {},
        filter: {},
        optChat: [],
        form: {
            thread_id: '',
            content: '',
            img_code: '',
        },
        url: '',
        roles: "",
        form_data: "",
        userid: '',
        imgPreview: [],
    }),
    created: function () {
        if (auth.user()) {
            this.userid = auth.user().id;
        }

        if(this.$route.params.filter) {
        this.filter = this.$route.params.filter;
        }
        
        if(Object.keys(this.filter).length<1)
        {
        if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
        }
        else{
        sessionStorage.filterData = JSON.stringify(this.filter);
        sessionStorage.filterStatus = 1;
        }

        this.loadGuru(this.filter);
        this.loadChat(this.filter);
        this.loadThread(this.filter);

        this.url = window.$apiUrl;
    },
    methods: {
        onChange(event) {
            this.form.content = event.target.value
        },
        popupImg(img_code){
            const e = this.$refs;
            let data = this.optChat;
            let img = data.filter(function(arr, i) {
                return (
                    arr.img_code == img_code
                );
            });
            this.imgPreview = img;
            $(e.formDialog).modal("show");
        },
        btnFile() {
            var input = document.querySelector("#uploadFile");
            input.click(); //if user click on the button then the input also clicked
        },
        urlForm(url, method, data){
            const e = this.$refs;
            authFetch(url, {
                method: method,
                body: data,
                })
                .then((res) => {
                return res.json();
                })
                .then((js) => {
                var input = $("#uploadFile");
                if (js.success) {
                    this.loadThread(this.filter);
                    this.loadChat(this.filter);
                    // input.replaceWith(input.val('').clone(true));
                    Swal.fire({
                        icon: 'success',
                        title: 'Informasi',
                        text: 'Data tersimpan!'
                    })
                    this.form_data = ''
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Informasi',
                        text: 'Data tidak tersimpan!'
                    })
                }
                });
        },
        saveImg() {
            const self = this;
            var urlSubmit = '/siswa/tanya_guru/files';
            self.doUpload().then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {}
                return res.json();
                }).then(js => {
                    console.log(js)
                    if (js.message[0]) {
                        self.form.img_code = js.message[0].code;
                    }

                    // alert(JSON.stringify(self.form));

                    var arr = {...self.form, ...self.filter};
                    var data = Object.keys(arr)
                        .map((key) =>
                            encodeURIComponent(key) + "=" + encodeURIComponent(arr[key])
                        ).join("&");
                    self.urlForm(urlSubmit, 'POST', data);
                });
        },
        uploadFoto: function (file) {
            var info = auth.user();
            var file_data = $("#uploadFile").prop("files");
            var form_data = new FormData();
            var fileInput = $("#uploadFile")[0];
            $.each(fileInput.files, function (k, file) {
                form_data.append("file", file);
            });
            form_data.append("subdir", "/chats/");
            form_data.append("id", info.id);
            this.form_data = form_data;
            this.saveImg();
            // return false;
        },
        doUpload: function () {
            Swal.fire({
              title: "Loading...",
              text: "Please wait",
              imageUrl: "images/ajaxloader.gif",
              showConfirmButton: false,
              allowOutsideClick: false
            });
            return authFetch("/siswa/tanya_guru/upload_data", {
                method: "POST",
                body: this.form_data,
                headers: {
                    "Content-Type": null,
                },
            });
        },
        loadThread(params){
            let data = '';
            data = Object.keys(params)
            .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            )
            .join("&");
            authFetch("/siswa/tanya_guru/chat_id?"+data).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        this.form.thread_id = json.data.id;
                        console.log(this.form.thread_id);
                    }
                });
            });
        },
        loadGuru(params){
            let data = '';
            data = Object.keys(params)
            .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            )
            .join("&");
            authFetch("/siswa/tanya_guru/guru?"+data).then((res) => {
                res.json().then((json) => {
                if (json.success) {
                    this.guru = json.data;
                }
                });
            });
        },
        loadChat(params){
            let data = '';
            data = Object.keys(params)
            .map(
            (key) =>
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            )
            .join("&");
            authFetch("/siswa/tanya_guru/chat?"+data).then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        this.optChat = json.data;
                    }
                });
            });
        },
        submitForm: function (ev) {
            const e = this.$refs;

            var arr = {...this.form, ...this.filter};

            var data = Object.keys(arr)
                .map(
                (key) =>
                    encodeURIComponent(key) + "=" + encodeURIComponent(arr[key])
                )
                .join("&");

            var urlSubmit = "/siswa/tanya_guru/chat";

            authFetch(urlSubmit, {
                method: "POST",
                body: data,
            })
                .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                    // console.log(js);
                    this.loadThread(this.filter);
                    this.loadChat(this.filter);
                    this.form.content = '';
                });
            ev.preventDefault();
        },
    },
    mounted(){}
    }
</script>
<style type="text/css" scoped>
</style>